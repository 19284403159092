<template>
  <div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
      @sortChange="handleSortChange"
    >
      <el-table-column
        width="250"
        :label="isManager ? '管理提成总入账（元）' : '员工红包总入账（元）'"
        slot="allIn"
        align="center"
      >
        <template slot-scope="{ row }">
          <span
            v-if="row.allIn"
            style="text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="allInFn(row)"
            >{{ row.allIn }}</span
          >
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column
        label="奖励总余额（元）"
        slot="allBalance"
        align="center"
      >
        <template slot-scope="{ row }">
          <div :style="{ color: row.allBalance < 0 ? 'red' : '' }">
            {{ row.allBalance }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="可提现余额（元）"
        slot="balance"
        align="center"
        sortable="custom"
        sort-by="01"
      >
        <template slot-scope="{ row }">
          <div :style="{ color: row.balance < 0 ? 'red' : '' }">
            {{ row.balance }}
          </div>
        </template>
      </el-table-column>

      <el-table-column width="250" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn(row)"
            >红包明细</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>

    <el-dialog
      class="allot-dialog"
      title="员工红包统计"
      :visible.sync="allotVisible"
      :close-on-click-modal="false"
      width="1200px"
      @closed="allotDialogClosed"
    >
      <GlobalForm
        class="global-form"
        :init-data="initData"
        :form-item-list="formItemList"
        :inline="true"
        :labelWidth="100"
        round
        @handleConfirm="handleConfirm"
        confirmBtnName="查询"
      >
        <el-form-item
          v-if="!isManager"
          class="el_from"
          label="所属门店"
          label-width="85px"
          slot="storeId"
          style="margin: 0"
        >
          <el-select
            v-model="storeId"
            placeholder="请选择门店"
            filterable
            clearable
          >
            <el-option
              v-for="item in storeIds"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
      </GlobalForm>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading2"
        :columns="redenvelopestatistics"
        :data="this.redenvellist"
        :currentPage="page2.pageNum"
        :pageSize="10"
        :total="page2.total"
        @handleCurrentChange="handleCurrentChange2"
        @sortChange="handleSortChange2"
      >
        <el-table-column
          width="250"
          label="员工姓名"
          slot="staffName"
          align="center"
        >
          <template slot-scope="{ row }">
            <span
              v-if="row.staffType == '03'"
              class="staffNamebox"
              style="background: #ffaf29"
              >店长</span
            >
            <span
              v-else-if="row.staffType == '04'"
              class="staffNamebox"
              style="background: #81c26a"
              >店员</span
            >
            <span>{{ row.staffName || "--" }}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-if="!isManager"
          label="所属门店"
          prop="storeName"
          slot="storeName"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.storeName || "--" }}
          </template>
        </el-table-column>

        <el-table-column
          label="奖励总余额（元）"
          slot="allBalance"
          align="center"
        >
          <template slot-scope="{ row }">
            <div :style="{ color: row.allBalance < 0 ? 'red' : '' }">
              {{ row.allBalance }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="可提现余额（元）"
          slot="balance"
          align="center"
          sortable="custom"
          sort-by="01"
        >
          <template slot-scope="{ row }">
            <div :style="{ color: row.balance < 0 ? 'red' : '' }">
              {{ row.balance }}
            </div>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>

    <!--入账区域  -->
    <el-dialog
      class="allot-dialog"
      title="员工红包明细"
      :visible.sync="handleShow"
      :close-on-click-modal="false"
      width="1400px"
      @closed="allotDialogClosed"
    >
      <div class="andleShowbox">
        <div
          :class="[
            [!isFrozen ? 'andleShowcolor andleShowstyle' : 'andleShowstyle'],
          ]"
          @click="frozenFn(false)"
        >
          可提现账户
        </div>
        <div
          :class="[
            [isFrozen ? 'andleShowcolor andleShowstyle' : 'andleShowstyle'],
          ]"
          @click="frozenFn(true)"
        >
          冻结账户
        </div>
      </div>

      <GlobalForm
        class="global-form"
        ref="redDialogForm"
        :init-data="initData"
        :form-item-list="redenvelopeslist"
        :inline="true"
        :labelWidth="100"
        round
        @handleConfirm="redenvelopeslistFn"
        confirmBtnName="查询"
      >
        <el-form-item
          class="el_from"
          label="账单类型"
          slot="serType"
          label-width="85px"
          style="margin: 0"
        >
          <el-select
            v-model="opt"
            placeholder="请选择"
            clearable
            @change="andleFn"
          >
            <el-option
              v-for="item in optOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="账单子类型"
          slot="serType"
          label-width="100px"
          style="margin: 0"
        >
          <el-select
            v-model="idArr"
            placeholder="请选择"
            filterable
            clearable
            @change="selectChange"
          >
            <el-option
              v-for="item in shanpsSelectList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="!isManager"
          class="el_from"
          label="所属门店"
          slot="storeId"
          label-width="74px"
          style="margin: 0"
        >
          <el-select
            v-model="storeId"
            placeholder="请选择门店"
            filterable
            clearable
            @change="selectChange"
          >
            <el-option
              v-for="item in storeIds"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-button
          icon="el-icon-download"
          slot="AddSlot"
          type="primary"
          @click="exportExcel"
          >导出Excel</el-button
        >
      </GlobalForm>

      <GlobalTable
        ref="GlobalTable"
        v-loading="loading3"
        :columns="redenvelopeslisttitle"
        :data="this.redenvedata"
        :currentPage="page3.pageNum"
        :total="page3.total"
        @handleCurrentChange="redenvellistFn"
      >
        <el-table-column label="账单类型" slot="opt" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.opt == "01" ? "入账" : "出账" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="账单子类型" slot="title" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.title || "--" }}</span>
          </template>
        </el-table-column>

        <el-table-column
          width="100"
          label="员工姓名"
          slot="staffName"
          align="center"
        >
          <template slot-scope="{ row }">
            <span
              v-if="row.staffType == '03'"
              class="staffNamebox"
              style="background: #ffaf29"
              >店长</span
            >
            <span
              v-else-if="row.staffType == '04'"
              class="staffNamebox"
              style="background: #81c26a"
              >店员</span
            >
            <span>{{ row.staffName || "--" }}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-if="!isManager"
          label="所属门店"
          prop="storeName"
          slot="storeName"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.storeName || "--" }}
          </template>
        </el-table-column>

        <el-table-column label="账单金额(元)" slot="amount" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.amount || "--" }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="提现审核人"
          slot="redpacketAudit"
          align="center"
          v-if="!isFrozen"
        >
          <template slot-scope="{ row }">
            <span>{{
              row.redpacketAudit ? `${row.redpacketAudit}` : "--"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="账单时间" slot="createTime" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.createTime || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="提现方式"
          slot="withdrawChannel"
          align="center"
          v-if="!isFrozen"
        >
          <template slot-scope="{ row }">
            <span v-if="row.withdrawChannel === 'wechat'">微信</span>
            <span v-else-if="row.withdrawChannel === 'alipay'">支付宝</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          label="支付宝账号"
          slot="alipayAccount"
          align="center"
          v-if="!isFrozen"
        >
          <template slot-scope="{ row }">
            <span>{{ row.alipayAccount || "--" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="订单编号" slot="orderNo" align="center">
          <template slot-scope="{ row }">
            <span
              v-if="row.orderChannel == 'recycle'"
              style="
                text-decoration: underline;
                cursor: pointer;
                color: #0981ff;
              "
              @click="handleClick(row)"
              >{{ row.orderNo || "--" }}</span
            >
            <span v-else>{{ row.orderNo || "--" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <!-- 入账区域END -->
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import { MessageBox } from "element-ui";
export default {
  components: {},
  props: {
    isManager: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day() - 6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      sortParams: {
        orderType: undefined,
        ackOrDesc: undefined,
      },
      sortParams2: {
        orderType: undefined,
        ackOrDesc: undefined,
      },
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      storeIds: [],
      storeId: "",
      Employeered: [],
      datalist: [],
      redenvedata: [],
      idArr: "",
      shanpsSelectList: [],
      handleShow: false,
      redenvellist: [],
      opt: "",
      isFrozen: false,
      optOption: [
        {
          label: "入账",
          value: "01",
        },
        {
          label: "出账",
          value: "02",
        },
      ],
      formItemList: [
        { slotName: "storeId" },
        {
          key: "staffName",
          type: "input",
          labelName: "员工姓名",
          placeholder: "请输入员工姓名",
          size: "mini",
        },
        {
          key: "staffMobile",
          type: "input",
          labelName: "手机号",
          placeholder: "请输入手机号",
          size: "mini",
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
      ],
      allotVisible: false,
      tableColumns: [
        { label: "门店商家", prop: "name" },
        { slotName: "allIn" },
        { label: "已出账（元）", prop: "allOut" },
        { label: "提现金额（元）", prop: "cashOut" },
        { label: "	订单退款扣除（元）", prop: "refund" },
        { slotName: "allBalance" },
        { slotName: "balance" },
        { label: "冻结余额（元）", prop: "frozenBalance" },
        { slotName: "operation" },
      ],
      redenvelopestatistics: [
        { slotName: "staffName" },
        { label: "手机号", prop: "staffMobile" },
        { slotName: "storeName" },
        { label: "总入账（元）", prop: "allIn" },
        { label: "已出账（元）", prop: "allOut" },
        { label: "红包提现（元）", prop: "cashOut" },
        { label: "订单退款扣除（元）", prop: "refund" },
        { slotName: "allBalance" },
        { slotName: "balance" },
        { label: "冻结余额（元）", prop: "frozenBalance" },
      ],
      redenvelopeslisttitle: [
        { slotName: "opt" }, // 类型
        { slotName: "title" }, // 类型
        { slotName: "staffName" },
        { label: "手机号", prop: "staffMobile" },
        { slotName: "storeName" },
        { slotName: "amount" }, // 入账金额
        // {slotName: "amount" },// 提现/扣除金额（元）
        { slotName: "redpacketAudit" }, // 提现审核人
        { slotName: "createTime" }, // 出账入账时间
        { slotName: "withdrawChannel" },
        { slotName: "alipayAccount" },
        { label: "流水单号", prop: "uniqNo" },
        { slotName: "orderNo" }, // 提现单号
      ],
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      page2: {
        pageNum: 1,
        pageSize: 10,
      },
      page3: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      loading2: false,
      loading3: false,
      redenvelopeslist: [
        { slotName: "serType" },
        { slotName: "storeId" },
        {
          key: "staffName3",
          type: "input",
          labelName: "员工姓名",
          placeholder: "请输入员工姓名",
        },
        {
          key: "staffMobile3",
          type: "input",
          labelName: "手机号",
          placeholder: "请输入手机号",
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "账单时间",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        {
          slotName: "botton",
        },
      ],
    };
  },
  created() {
    let startTime = moment()
      .day(moment().day())
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    startTime = Date.parse(startTime);
    endTime = Date.parse(endTime);
    this.entryTime = [startTime, endTime];
    this.handleCurrentChange();
  },
  mounted() {},
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.handleCurrentChange();
    },
  },
  methods: {
    handleSortChange(v) {
      const { order, orderBy, desc } = v;
      const sortParams = {
        orderType: order ? orderBy : undefined,
        ackOrDesc: order ? (desc ? "01" : "02") : undefined,
      };
      this.sortParams = sortParams;
      this.handleCurrentChange();
    },
    handleSortChange2(v) {
      const { order, orderBy, desc } = v;
      const sortParams = {
        orderType: order ? orderBy : undefined,
        ackOrDesc: order ? (desc ? "01" : "02") : undefined,
      };
      this.sortParams2 = sortParams;
      this.handleCurrentChange2();
    },
    // 订单编号跳转详情
    handleClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },

    // 红包明细查询
    redenvellistFn(val) {
      if (val) {
        this.page3.pageNum = val;
      } else {
        this.page3.pageNum = 1;
      }
      let params = {
        startTime: this.datalist.startTime || this.entryTime[0],
        endTime: this.datalist.endTime || this.entryTime[1],
        opt: this.opt,
        isFrozen: this.isFrozen,
        pageNum: this.page3.pageNum,
        pageSize: this.page3.pageSize || 10,
        staffName: this.datalist.staffName || "",
        staffMobile: this.datalist.staffMobile || "",
        companyId: this.iiid,
        serType: this.idArr,
        storeId: this.storeId,
        isManager: this.isManager,
      };

      this.loading3 = true;
      _api.redpacketdetail(params).then((res) => {
        if (res.code === 1) {
          this.redenvedata = res.data.records;
          this.page3.pageNum = res.data.current;
          this.page3.total = res.data.total;
          console.log(res, "~~~~~~~~~~~~~~~~~~~~~~~~~~~");
        }
        this.loading3 = false;
      });
    },
    // 红包明细查询方法
    redenvelopeslistFn(data) {
      console.log(data);
      let redenvelopeslist = {};
      let startTime = "";
      let endTime = "";
      if (data.entryTime === null) {
        (startTime = ""), (endTime = "");
      } else if (data.startTime && data.endTime) {
        (startTime = data.startTime), (endTime = data.endTime);
      } else {
        (startTime = moment(data.entryTime[0]).format("x")),
          (endTime = moment(data.entryTime[1]).format("x"));
      }

      redenvelopeslist = {
        startTime: startTime,
        endTime: endTime,
        opt: this.opt,
        isFrozen: this.isFrozen,
        pageNum: 1,
        pageSize: 10,
        staffName: data.staffName3 || "" || data.staffName,
        staffMobile: data.staffMobile3 || "" || data.staffMobile,
        companyId: this.iiid,
        serType: this.idArr,
        storeId: this.storeId,
        isManager: this.isManager,
      };
      this.datalist = redenvelopeslist;
      this.loading3 = true;
      _api
        .redpacketdetail(redenvelopeslist)
        .then((res) => {
          this.redenvedata = res.data.records;
          this.page3.pageNum = res.data.current;
          this.page3.total = res.data.total;
        })
        .finally(() => {
          this.loading3 = false;
        });
    },
    // 导出订单方法
    exportExcel(e) {
      let redenvelopeslist = [];
      redenvelopeslist = {
        startTime: this.datalist.startTime || this.entryTime[0],
        endTime: this.datalist.endTime || this.entryTime[1],
        opt: this.opt,
        isFrozen: this.isFrozen,
        pageNum: this.page3.pageNum,
        pageSize: 10,
        staffName: this.datalist.staffName || "",
        staffMobile: this.datalist.staffMobile || "",
        companyId: this.iiid,
        serType: this.idArr,
        storeId: this.storeId,
        isManager: this.isManager,
      };

      console.log(redenvelopeslist);
      _api.redpacketdetailexcel(redenvelopeslist).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            let start = moment(Number(redenvelopeslist.startTime)).format(
              "YYYY.MM.DD"
            );
            let end = moment(Number(redenvelopeslist.endTime)).format(
              "YYYY.MM.DD"
            );
            const fileName = this.isFrozen
              ? `【冻结奖励明细】${start || ""}${end ? "-" + end : ""}.xlsx`
              : `【可提现奖励明细】${start || ""}${end ? "-" + end : ""}.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },

    // 出账入账按钮切换
    andleFn(e) {
      this.opt = e;
      this.idArr = "";
      _api
        .redpacketgetSerTypeList({
          opt: e,
          isFrozen: this.isFrozen,
          isManager: this.isManager,
        })
        .then((res) => {
          this.shanpsSelectList = res.data;
        });
      this.redenvellistFn();
    },

    // 员工红包统计查询
    handleConfirm(data) {
      console.log(data, "员工弹窗弹出");
      this.initData.entryTime = data.entryTime;
      let params = {};
      this.Employeered = data;
      this.loading2 = true;
      if (data) {
        let startTime = "";
        let endTime = "";
        if (data.entryTime === null) {
          (startTime = ""), (endTime = "");
        } else {
          (startTime = moment(data.entryTime[0]).format("x")),
            (endTime = moment(data.entryTime[1]).format("x"));
        }
        params = {
          staffName: data.staffName || "",
          staffMobile: data.staffMobile || "",
          startTime: startTime,
          endTime: endTime,
          pageNum: 1,
          pageSize: 10,
          companyId: this.companyId,
          storeId: this.storeId,
          isManager: this.isManager,
          ...this.sortParams2,
        };
      } else {
        params = {
          staffName: "",
          staffMobile: "",
          startTime: this.entryTime[0],
          endTime: this.entryTime[1],
          pageNum: 1,
          pageSize: 10,
          companyId: this.companyId,
          isManager: this.isManager,
          ...this.sortParams2,
        };
      }

      _api
        .getStaffInCompanyStatistic(params)
        .then((res) => {
          console.log(res, "员工表格回调");
          this.redenvellist = res.data.records;
          this.page2.pageNum = res.data.current;
          this.page2.total = res.data.total;
        })
        .finally(() => {
          this.loading2 = false;
        });
    },

    // 员工红包统计
    allInFn(row) {
      console.log(row);
      this.storeId = "";
      this.companyId = row.id;
      this.handleConfirm({
        entryTime: [
          Number(this.SeachParams.startTime || this.entryTime[0]),
          Number(this.SeachParams.endTime || this.entryTime[1]),
        ],
      });
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        console.log(res);
        this.storeIds = res.data;
      });
      this.allotVisible = true;
    },
    allotDialogClosed() {
      this.allotVisible = false;
      this.opt = "";
      this.idArr = "";
      this.isFrozen = false;
      this.$refs.redDialogForm.formData = {
        entryTime: [
          moment()
            .day(moment().day() - 6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      };
      this.datalist = {};
    },
    //   切换页
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        // accountType: this.accountType,
        companyId: this.SeachParams.companyIds,
        isManager: this.isManager,
        startTime: this.SeachParams.startTime || this.entryTime[0],
        endTime: this.SeachParams.endTime || this.entryTime[1],
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        ...this.sortParams,
      };
      this.loading = true;
      _api
        .getCompanyStatistic(baseRequest)
        .then((res) => {
          console.log(res);
          if (res.code === 1 && res.data && res.data.records) {
            this.seachDataList = res.data.records;
            this.page.total = res.data.total;
            this.page.pageNum = res.data.current;
            this.loading = false;
            this.$emit("billNumber", {
              companyUniqCount: res.data.companyUniqCount,
              merchantUniqCount: res.data.merchantUniqCount,
            });
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 红包明细
    handleBtn(row) {
      console.log(row);
      this.iiid = row.id;
      this.storeId = "";
      this.handleShow = true;
      this.initData.entryTime = [
        Number(this.SeachParams.startTime || this.entryTime[0]),
        Number(this.SeachParams.endTime || this.entryTime[1]),
      ];
      this.redenvellistFn();

      _api
        .redpacketgetSerTypeList({
          opt: this.opt,
          isFrozen: this.isFrozen,
          isManager: this.isManager,
        })
        .then((res) => {
          this.shanpsSelectList = res.data;
        });
      _api.getStoreSelectList({ companyId: this.iiid }).then((res) => {
        console.log(res);
        this.storeIds = res.data;
      });
    },
    //   员工统计分页方法
    handleCurrentChange2(val) {
      console.log(val);
      if (val) {
        this.page2.pageNum = val;
      } else {
        this.page2.pageNum = 1;
      }
      let params;
      if (this.Employeered) {
        let startTime = "";
        let endTime = "";
        if (this.Employeered.entryTime === null) {
          (startTime = ""), (endTime = "");
        } else {
          (startTime = moment(this.Employeered.entryTime[0]).format("x")),
            (endTime = moment(this.Employeered.entryTime[1]).format("x"));
        }
        params = {
          staffName: this.Employeered.staffName || "",
          staffMobile: this.Employeered.staffMobile || "",
          startTime: startTime,
          endTime: endTime,
          pageNum: this.page2.pageNum,
          pageSize: 10,
          companyId: this.companyId,
          storeId: this.storeId,
          isManager: this.isManager,
          ...this.sortParams2,
        };
      } else {
        params = {
          staffName: "",
          staffMobile: "",
          startTime: this.entryTime[0],
          endTime: this.entryTime[1],
          pageNum: this.page2.pageNum,
          pageSize: 10,
          companyId: this.companyId,
          isManager: this.isManager,
          ...this.sortParams2,
        };
      }

      _api
        .getStaffInCompanyStatistic(params)
        .then((res) => {
          console.log(res, "员工表格回调");
          this.redenvellist = res.data.records;
          this.page2.pageNum = res.data.current;
          this.page2.total = res.data.total;
        })
        .finally(() => {
          this.loading2 = false;
        });
    },
    // 可提现、冻结切换
    frozenFn(e) {
      this.isFrozen = e;
      this.idArr = "";
      _api
        .redpacketgetSerTypeList({
          opt: this.opt,
          isFrozen: this.isFrozen,
          isManager: this.isManager,
        })
        .then((res) => {
          this.shanpsSelectList = res.data;
        });
      this.redenvellistFn();
    },
    selectChange() {
      this.redenvellistFn();
    },
  },
};
</script>

<style lang="scss" scoped>
.staffNamebox {
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 5px;
  color: #fff;
}
.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 20px;
  .andleShowstyle {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }
  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}
</style>
